@use "@angular/material" as mat;

@include mat.core();

/* Styles for Light/Dark Theming */
$brand-color: #014510;
$accent-green: #006600;
$accent-green-light: #009900;
$accent-orange: #ff9900;
$accent-orange-background: #fff5e5;
$accent-red: #cc0000;
$accent-blue: #0065ff;
$star-rating-yellow: #fec401;
$blue: #0066cc;
$shade1: rgb(0, 0, 0);
$shade2: #0d0d0d;
$shade3: #1a1a1a;
$shade4: #333333;
$shade5: #808080;
$shade6: #cccccc;
$shade7: #e5e5e5;
$shade8: #f2f2f2;
$shade9: rgb(255, 255, 255);

/* Chart */
$chart-red: #db4e38;
$chart-green: #17ba99;
$chart-gray: rgba(148, 159, 177, 1);

:host {
  --brand-color: $brand-color;
  --accent-green: $accent-green;
  --accent-orange: $accent-orange;
  --accent-red: $accent-red;
}

// mixin that enables css variables in light mode
@mixin lighten() {
  --shade1: #{$shade1};
  --shade2: #{$shade2};
  --shade3: #{$shade3};
  --shade4: #{$shade4};
  --shade5: #{$shade5};
  --shade6: #{$shade6};
  --shade7: #{$shade7};
  --shade8: #{$shade8};
  --shade9: #{$shade9};
}

// mixin that enables css variables in dark mode
@mixin darken() {
  --shade1: #{$shade9};
  --shade2: #{$shade8};
  --shade3: #{$shade7};
  --shade4: #{$shade6};
  --shade5: #{$shade5};
  --shade6: #{$shade4};
  --shade7: #{$shade3};
  --shade8: #{$shade2};
  --shade9: #{$shade1};
}

$mat-primary: (
  50: #e0e0e0,
  100: #b3b3b3,
  200: #808080,
  300: #4d4d4d,
  400: #262626,
  500: #000000,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-accent: (
  50: #e0ede0,
  100: #b3d1b3,
  200: #80b380,
  300: #4d944d,
  400: #267d26,
  500: #006600,
  600: #005e00,
  700: #005300,
  800: #004900,
  900: #003800,
  A100: #6eff6e,
  A200: #3bff3b,
  A400: #08ff08,
  A700: #00ee00,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-warn: (
  50: #f9e0e0,
  100: #f0b3b3,
  200: #e68080,
  300: #db4d4d,
  400: #d42626,
  500: #cc0000,
  600: #c70000,
  700: #c00000,
  800: #b90000,
  900: #ad0000,
  A100: #ffd7d7,
  A200: #ffa4a4,
  A400: #ff7171,
  A700: #ff5858,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$light-primary: mat.define-palette($mat-primary);
$light-accent: mat.define-palette($mat-accent);
$light-warn: mat.define-palette($mat-warn);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
    ),
  )
);

$background-color-0: #ffcdd2;
$background-color-1: #c8e6c9;
$background-color-2: #bbdefb;
$background-color-3: #d1c4e9;
$background-color-4: #ffecb3;
$background-color-5: #b2dfdb;
$background-color-6: #b3e5fc;
$background-color-7: #d7ccc8;
$background-color-8: #f0f4c3;
$background-color-9: #f5f5f5;
$background-color-10: #ffab91;
$background-color-11: #ffd54f;
$background-color-12: #ffcc80;
$background-color-13: #e6ee9c;
$background-color-14: #cfd8dc;
$background-color-15: #ffab40;
$background-color-16: #80cbc4;
$background-color-17: #80deea;
$background-color-18: #a5d6a7;
$background-color-19: #90caf9;

@include mat.core-color($light-theme);
@include mat.button-color($light-theme);
@include mat.radio-color($light-theme);
@include mat.slide-toggle-color($light-theme);
