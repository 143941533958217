@import "variables";

[class^="mat-"],
[class^="mdc-"] {
  letter-spacing: 0;
}

.mat-mdc-header-row {
  color: var(--shade5) !important;
  font-size: 12px !important;
}

.mat-mdc-unelevated-button[disabled][disabled] {
  border: none;
  background-color: $shade6;
}

.mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 !important;
}

.mat-select-value {
  color: var(--shade1) !important;
}

.mat-mdc-select {
  color: var(--shade1) !important;
}

.mat-select-arrow {
  color: var(--shade1) !important;
}

.mat-mdc-select-arrow {
  display: none;
}

.mat-mdc-select-trigger::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  color: var(--shade1) !important;
  display: inline-block;
}

.mat-mdc-option.mat-selected .mdc-list-item__primary-text {
  margin-left: 0;
}

.mat-form-field-underline {
  color: var(--shade1) !important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--shade2) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--shade5) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--shade2) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--shade2) !important;
}

.mdc-list-item:hover::before {
  opacity: 0 !important;
}

.mat-mdc-list-item {
  padding: 0 6px !important;

  &:hover,
  &:focus {
    background: rgba(var(--shade9), 0.3) !important;
  }

  &.mdc-list-item--with-one-line {
    @media screen and (min-width: $desktop) {
      height: 44px !important;
    }

    &.child-list-item {
      height: 38px !important;
    }

    .mdc-list-item:focus::before,
    .mdc-list-item:hover::before {
      opacity: 0 !important;
    }
  }
}

// Mat Table
.mat-mdc-table {
  --mat-table-background-color: var(--shade9);
  --mat-table-row-item-label-text-color: var(--shade1);
  --mat-table-row-item-outline-color: var(--shade6);

  .mdc-data-table__header-row {
    height: 48px;
  }

  .mdc-data-table__row {
    height: 45px;
    transition: background-color 0.3s;

    &:hover {
      background-color: var(--shade8) !important;
    }

    &.no-hover {
      background-color: inherit !important;
    }

    &.clickable {
      cursor: pointer;
    }
  }
}

// Matt Paginator
.mat-mdc-paginator {
  --mat-paginator-container-text-color: var(--shade1);
  --mat-paginator-container-background-color: var(--shade9);
  --mat-paginator-enabled-icon-color: var(--shade3);
  --mat-paginator-disabled-icon-color: var(--shade5);
}

// Mat Dialog
.dialog-driver,
.dialog-event {
  .mat-mdc-dialog {
    &-container {
      .mdc-dialog {
        &__surface {
          overflow: visible;
          border-radius: 0;
          background-color: var(--shade8);
        }

        &__content {
          overflow: visible;
          // max-height: unset;
          padding: 0;

          .mat-mdc-tab-body-content {
            overflow: hidden;
          }
        }
      }
    }

    &-content {
      .mat-mdc-tab {
        &-group {
          height: 100%;
        }

        &-header {
          padding: 20px 20px 16px;
        }

        &-body {
          overflow: visible;

          &-wrapper {
            overflow: visible;
          }
        }
      }
    }

    &-actions {
      padding: 20px 20px 18px;
      position: relative;
      background: var(--shade9);
    }
  }
}

.dialog-default {
  .mat-mdc-dialog {
    &-container {
      .mdc-dialog {
        &__surface {
          overflow: visible;
          border-radius: 0;
          background-color: var(--shade8);
        }

        &__content {
          overflow: visible;
          padding: 0;

          .mat-mdc-tab-body-content {
            overflow: hidden;
          }
        }
      }
    }

    &-content {
      .mat-mdc-tab {
        &-group {
          height: 100%;
        }

        &-header {
          padding: 20px 20px 16px;
        }

        &-body {
          overflow: visible;

          &-wrapper {
            overflow: visible;
          }
        }
      }
    }

    &-actions {
      padding: 20px 20px 18px;
      position: relative;
      background: var(--shade9);
    }
  }
}

.mat-mdc-dialog {
  &-actions {
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid var(--shade6);

    .dialog {
      &-close {
        position: absolute;
        top: 14px;
        right: 20px;

        .fas {
          font-size: 24px;
          cursor: pointer;
        }
      }

      &-title {
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-template-rows: 46px 54px;
        grid-template-areas: "avatar info" "avatar actions";
        width: 100%;
        column-gap: 20px;

        .driver {
          &-avatar {
            grid-area: avatar;

            .user-avatar {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          &-info {
            grid-area: info;
            display: flex;
            align-items: center;
            column-gap: 5px;

            h2 {
              margin: 0 12px 0 0;
            }

            button {
              height: 22px;
              padding: 0 !important;
              font-size: 14px;

              .fas {
                color: var(--shade5);
              }
            }
          }

          &-actions {
            grid-area: actions;
            padding: 4px 0;
          }
        }
      }
    }
  }

  &-container {
    //background-color: var(--shade8) !important;
    // --mdc-dialog-container-color: var(--shade8) !important;

    .mdc-dialog {
      &__title {
        font: 700 20px/1 Roboto, Arial, Helvetica, sans-serif !important;
        letter-spacing: 0;
        line-height: 22px;
        color: var(--shade1) !important;
        position: absolute;
        top: 15px;
        padding: 0 5px 9px;

        &:before {
          content: unset !important;
        }
      }

      &__content {
        color: var(--shade1) !important;
        font: 400 14px/1 Roboto, Arial, Helvetica, sans-serif !important;
        line-height: 1.1 !important;
        letter-spacing: 0 !important;

        .ng-select-container .ng-value .ng-value-icon {
          padding-left: 5px;
        }

        .ng-dropdown-panel {
          background: var(--shade9) !important;
          margin-top: 10px;
          margin-left: -5px;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
          box-sizing: border-box;
          cursor: pointer;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
          background-color: var(--shade8);
        }
      }
    }
  }
}

.light {
  .timepicker-overlay {
    .timepicker {
      .timepicker__header {
        background: var(--shade1) !important;
      }

      .timepicker__body {
        .clock-face__number {
          span.active {
            background: var(--shade1) !important;
            clock-hand-color: var(--shade1) !important;
          }
        }
      }
    }
  }
}

.dark {
  .cdk-overlay-pane {
    border: 1px solid var(--shade6) !important;
  }
  .timepicker-overlay {
    .timepicker {
      .timepicker__header {
        background: var(--shade9) !important;
      }

      .timepicker__body {
        .clock-face__number {
          span.active {
            background: var(--shade9) !important;
            clock-hand-color: var(--shade9) !important;
          }
        }
      }
    }
  }
}

.ngx-material-timepicker-toggle {
  &:focus {
    background: unset !important;
  }
}

.period-selector__button--active {
  color: var(--shade1) !important;
}

.period-control__button::after,
.ngx-timepicker-control--active::after {
  background-color: unset !important;
}

//.period-control__button::after {
//  background-color: unset !important;
//}

.timepicker-overlay,
.timepicker-backdrop-overlay {
  z-index: 1001 !important;
}

.timepicker-overlay {
  .ngx-timepicker {
    border-bottom: none !important;
  }

  .timepicker {
    .timepicker__actions {
      justify-content: space-between;
    }
  }
}

.dialog-center {
  .mat-mdc-dialog {
    &-actions {
      padding: 8px 20px;

      &.end {
        justify-content: end;
        padding: 8px 20px 26px !important;
      }
    }

    &-container {
      .mdc-dialog {
        &__content {
          padding: 24px 20px;
        }
      }

      section {
        padding: 0 0 24px;
        border-bottom: 1px solid var(--shade6);
        margin: 0 0 22px;

        h3 {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.cdk-overlay-pane {
  &.single {
    .mat-mdc-dialog-container {
      .mdc-dialog__surface {
        background-color: var(--shade9);
      }
    }
    .mat-mdc-dialog-actions {
      padding: 8px 20px;
    }
    .mat-mdc-dialog-actions.end {
      justify-content: end;
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 0 !important;
}

// Mat Tabs
.mat-mdc-tab {
  letter-spacing: 0 !important;

  &:not(.mdc-tab--stacked) {
    min-width: unset;
    height: 26px !important;
    padding: 0;
  }

  &-group {
    &.mat-mdc-tab-group-stretch-tabs {
      & > .mat-mdc-tab-header {
        .mat-mdc-tab {
          flex-grow: unset !important;
        }
      }
    }
  }

  &-labels {
    column-gap: 40px;
  }

  &-label {
    color: var(--shade5) !important;

    &-container {
      padding: 4px 20px 0;
    }
  }

  &.mdc-tab--active {
    .mdc-tab__text-label {
      color: var(--shade1) !important;
    }
  }

  .mdc-tab {
    &__content {
      align-items: flex-start;
    }

    &__text-label {
      align-items: center;
      gap: 8px;
      color: var(--shade4) !important;

      .fas {
        color: var(--shade5);
      }
    }

    &--active {
      .mdc-tab__text-label {
        color: var(--shade1) !important;
      }
    }

    &-indicator--active {
      color: var(--shade4) !important;
    }

    &-indicator__content--underline {
      border-color: var(--shade1) !important;
      border-top-width: 4px;
    }
  }
}

.mdc-tab--active {
  .fas {
    color: var(--shade1) !important;
  }
}

// Mat Button
.mat-mdc-button,
.mdc-button {
  letter-spacing: 0;
  padding: 0 16px !important;
  font-size: 12px;
  --mdc-text-button-label-text-color: var(--shade1) !important;
  --mat-mdc-button-persistent-ripple-color: var(--shade1) !important;
  --mat-mdc-button-ripple-color: rgba(var(--shade1), 0.1) !important;

  &.mat-primary {
    border: 1px solid var(--shade4);
    --mdc-filled-button-container-color: var(--shade1) !important;
    --mdc-filled-button-label-text-color: var(--shade9) !important;
  }

  &__label {
    display: flex;
    align-items: center;

    .fas,
    .far {
      margin: 0 7px 0 0;
    }
  }
}

.mat-mdc-icon-button,
.mdc-icon-button,
.mdc-fab {
  display: flex !important;
  justify-content: center;
  align-items: center;

  &.mat-primary {
    &.inverse {
      --mdc-icon-button-icon-color: var(--shade1);
      --mat-mdc-button-persistent-ripple-color: var(--shade1);
      --mat-mdc-button-ripple-color: rgba(var(--shade7), 0.1);
      --mdc-fab-container-color: transparent;
    }
  }
}

.mat-warn {
  &.mat-mdc-outlined-button:not(:disabled) {
    --mdc-outlined-button-outline-color: $accent-red !important;
  }
}

.mat-mdc-outlined-button {
  &.mat-primary {
    --mdc-outlined-button-outline-color: var(--shade6) !important;
    --mdc-outlined-button-label-text-color: var(--shade5) !important;
  }
}

.human {
  .mat-mdc-checkbox {
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
        [data-indeterminate="true"]
      ) {
      & ~ .mdc-checkbox__background {
        border-radius: 50%;
        border: 2px solid var(--shade5) !important;

        .mdc-checkbox__mixedmark {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: var(--shade5);
          opacity: 1;
          transform: none;
        }
      }
    }

    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
      border-radius: 50%;
      border: 2px solid $accent-red !important;

      .mdc-checkbox__checkmark {
        display: none;
      }

      .mdc-checkbox__mixedmark {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $accent-red;
        opacity: 1;
        transform: none;
      }
    }
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: var(--shade7);

    .mdc-evolution-chip__text-label {
      display: flex;
      align-items: center;
      letter-spacing: 0;
      font-size: 12px;
      color: var(--shade1);

      .far,
      .fas {
        color: var(--shade1);
        margin: 0 0 0 10px;
        cursor: pointer;

        &:hover {
          color: $accent-red;
        }
      }
    }
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-container-height: 30px;
    border-radius: 0;
  }

  .mdc-evolution-chip-set .mdc-evolution-chip {
    margin-left: 4px;
  }
}

// Mat DatePicker
.date-picker {
  height: 59px;

  mat-icon {
    cursor: pointer;
  }

  &.mat-focused .mat-mdc-form-field-focus-overlay,
  &.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }

  .mat-datepicker-toggle-active {
    color: var(--shade1);
  }

  &.mat-focused {
    .mdc-floating-label {
      display: none;
    }
  }

  .mdc-floating-label--float-above {
    display: none;
  }

  .mdc-text-field .mdc-text-field__input {
    caret-color: var(--shade1);
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding: 5px 0;
    min-height: unset;
  }

  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 7px;
    margin-top: -11px;
  }

  .mat-mdc-icon-button {
    &.mat-mdc-button-base,
    .mat-mdc-button-touch-target {
      width: 38px;
      height: 38px;
      margin: 0 0 0 -1px;
    }

    &.mat-mdc-button-base {
      padding: 7px;

      .mat-mdc-button-persistent-ripple.mdc-icon-button__ripple {
        display: none;
      }
    }

    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
      background-color: var(--shade9);
    }
  }

  .mdc-line-ripple {
    display: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--shade9);
    border-radius: 18px;
    border: 1px solid var(--shade6);
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-floating-label {
        top: 17px;
      }
    }
  }
}

.mat-calendar {
  &-body {
    &-cell-content {
      border-radius: 5px !important;
      font-size: 14px !important;

      &.mat-focus-indicator {
        color: var(--shade3);
      }
    }

    &-in-range::before {
      background: var(--shade7) !important;
    }

    &-selected {
      background-color: var(--shade1) !important;
      color: var(--shade9) !important;
    }

    &-label {
      color: var(--shade5) !important;
    }

    &-cell:not(.mat-calendar-body-disabled):hover {
      &
        > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
          .mat-calendar-body-comparison-identical
        ) {
        background: rgba(128, 128, 128, 0.2) !important; // var not work
      }
    }
  }

  &-arrow {
    fill: var(--shade2) !important;
  }

  &-table-header {
    th {
      color: var(--shade5) !important;
    }
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  .mdc-text-field__input {
    color: var(--shade1) !important;
  }
}

.mat-date-range-input-separator {
  color: var(--shade1) !important;
}

.mat-datepicker {
  &-toggle {
    color: var(--shade2) !important;
  }

  &-content {
    background-color: var(--shade9) !important;
    color: var(--shade1) !important;
    border: 1px solid var(--shade6);

    .mat-calendar-previous-button,
    .mat-calendar-next-button {
      color: var(--shade2) !important;
    }
  }
}

.cdk-program-focused {
  .mat-calendar-body-active {
    & > .mat-calendar-body-cell-content {
      &:not(.mat-calendar-body-selected) {
        &:not(.mat-calendar-body-comparison-identical) {
          background-color: var(--shade7);
        }
      }
    }
  }
}

app-date-time-picker {
  --mat-form-field-container-text-size: 0.83rem;
  --mat-form-field-outlined-label-text-populated-size: 0.83rem;
  --mdc-filled-text-field-caret-color: var(--shade5);
  --mdc-filled-text-field-focus-active-indicator-color: transparent;
  --mdc-filled-text-field-focus-label-text-color: var(--shade5);
  --mdc-filled-text-field-container-color: transparent;
  --mdc-filled-text-field-disabled-container-color: transparent;

  .mdc-text-field--filled.mdc-text-field--disabled {
    background-color: var(--shade9);
    border-radius: 5px;
  }

  .mat-mdc-form-field {
    height: 53px;
    width: 100%;
    border: 1px solid var(--shade6);
    border-radius: 5px;
    //margin-bottom: 15px;
    margin-top: 15px;

    &-flex {
      height: 51px;
    }

    &-has-icon-suffix {
      .mat-mdc-text-field-wrapper {
        padding: 0 0 0 10px !important;
        overflow: visible !important;
      }
    }
  }

  .mat-mdc-icon-button {
    &.mat-mdc-button-base {
      padding: 5px;
    }
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mdc-text-field {
    &--filled {
      --mdc-filled-text-field-active-indicator-height: 0;
      --mdc-filled-text-field-focus-active-indicator-height: 0;
    }

    &--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
      .mat-mdc-form-field-infix {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }

    &--no-label:not(.mdc-text-field--textarea) {
      .mat-mdc-form-field-input-control.mdc-text-field__input,
      .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
        &::placeholder {
          font-size: 14px;
          color: var(--shade5);
        }
      }
    }
  }
}

.mat-calendar-body-cell-content {
  border-width: 0 !important;
}

.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: var(--shade6);
  --mdc-icon-button-disabled-icon-color: var(--shade6);
}

.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: var(--shade6);
  --mdc-outlined-button-disabled-label-text-color: var(--shade6);
  --mdc-outlined-button-outline-color: var(--shade7);
  --mdc-outlined-button-disabled-outline-color: var(--shade7);
}

.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: var(--shade6);
  --mdc-text-button-label-text-color: var(--shade6);
}

.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: var(--shade1);
}

.mat-datepicker-actions {
  justify-content: space-between !important;

  .fas {
    margin-right: 6px;
  }
}

.ngx-timepicker-control {
  &__input {
    background-color: transparent;
  }

  &__arrow {
    color: var(--shade5) !important;

    &:hover {
      color: var(--shade1) !important;
    }
  }
}

.period-control {
  &__arrow {
    color: var(--shade5) !important;
  }
}

.ngx-material-timepicker-toggle {
  & > span {
    svg {
      fill: var(--shade1) !important;
    }
  }
}

.timepicker {
  &__body,
  &__actions {
    background-color: var(--shade9) !important;
  }
}

.clock-face {
  background-color: var(--shade8) !important;

  &__number--outer {
    & > span {
      color: var(--shade1) !important;
    }
  }

  &__clock-hand {
    background-color: var(--shade1) !important;
  }

  &__clock-hand_minute {
    &:before {
      background-color: var(--shade1) !important;
      border-color: var(--shade1) !important;
    }
  }
}

.dark {
  .timepicker-overlay .timepicker .timepicker__body .clock-face__number span.active {
    background: var(--shade1) !important;
  }
}

.ngx-timepicker-control {
  &__input {
    background-color: transparent;
  }

  &__arrow {
    color: var(--shade5) !important;

    &:hover {
      color: var(--shade1) !important;
    }
  }
}

.period {
  &-control {
    &__arrow {
      color: var(--shade5) !important;
    }
  }

  &-selector {
    background-color: var(--shade7) !important ;

    &__button {
      color: var(--shade1) !important;

      &:focus {
        background-color: var(--shade5) !important;
        color: var(--shade9) !important;
      }
    }
  }
}

.ngx-material-timepicker-toggle {
  & > span {
    svg {
      fill: var(--shade1) !important;
    }
  }
}

.timepicker {
  &__body,
  &__actions {
    background-color: var(--shade9) !important;
  }
}

.clock-face {
  background-color: var(--shade8) !important;

  &__number--outer {
    & > span {
      color: var(--shade1) !important;
    }
  }

  &__clock-hand {
    background-color: var(--shade1) !important;
  }

  &__clock-hand_minute {
    &:before {
      background-color: var(--shade1) !important;
      border-color: var(--shade1) !important;
    }
  }
}

.dark {
  .timepicker-overlay .timepicker .timepicker__body .clock-face__number span.active {
    background: var(--shade1) !important;
  }
}

.ngx-timepicker-control {
  &__input {
    background-color: transparent;
  }
}

.ngx-material-timepicker-toggle {
  & > span {
    svg {
      fill: var(--shade1) !important;
    }
  }
}

.timepicker {
  &__body,
  &__actions {
    background-color: var(--shade9) !important;
  }
}

.clock-face {
  background-color: var(--shade8) !important;

  &__number--outer {
    & > span {
      color: var(--shade1) !important;
    }
  }

  &__clock-hand {
    background-color: var(--shade1) !important;
  }

  &__clock-hand_minute {
    &:before {
      background-color: var(--shade1) !important;
      border-color: var(--shade1) !important;
    }
  }
}

.dark {
  .timepicker-overlay .timepicker .timepicker__body .clock-face__number span.active {
    background: var(--shade1) !important;
  }
}

// Mat Checkbox
.mat-mdc-checkbox {
  &.mat-primary {
    --mdc-checkbox-selected-focus-icon-color: var(--shade1);
    --mdc-checkbox-selected-hover-icon-color: var(--shade1);
    --mdc-checkbox-selected-icon-color: var(--shade1);
    --mdc-checkbox-selected-pressed-icon-color: var(--shade1);
    --mdc-checkbox-selected-focus-state-layer-color: var(--shade1);
    --mdc-checkbox-selected-hover-state-layer-color: var(--shade1);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--shade1);
    /*--mdc-checkbox-disabled-selected-icon-color: rgba(var(--shade1), 0.38);*/
    /*--mdc-checkbox-disabled-unselected-icon-color: rgba(var(--shade1), 0.38);*/
    --mdc-checkbox-selected-checkmark-color: var(--shade9);

    &.assigned-to-me {
      label {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 13px;
      }
    }

    .mdc-form-field {
      .mdc-checkbox {
        .mdc-checkbox__ripple {
          display: none;
        }
      }
    }
  }

  .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
    ~ .mdc-checkbox__background {
    border-color: var(--shade5) !important;
    background-color: var(--shade5) !important;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__mixedmark {
    width: 7px;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:disabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__mixedmark {
    width: 7px;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    width: 13px;
    margin-left: 2px;
    margin-top: 2px;
  }

  .mdc-label {
    color: var(--shade1);
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate="true"]
    )
    ~ .mdc-checkbox__background {
    border-color: var(--shade5) !important;
  }
}

.mdc-checkbox__background {
  border-width: 1px !important;
}

// Mat Badge
.mat-badge {
  &-content {
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mat-badge-small {
  &.mat-badge-overlap {
    &.mat-badge-after {
      .mat-badge-content {
        right: 3px;
      }
    }
  }

  &.mat-badge-above {
    .mat-badge-content {
      top: 3px;
    }
  }

  .mat-badge-content {
    z-index: 2;
  }
}

// Matt SlideToggle
.mdc-switch {
  width: 40px !important;

  .mdc-switch__track {
    height: 20px !important;
    border-radius: 10px !important;

    // &:before,
    // &:after {
    //   background: var(--shade1) !important;
    // }
  }

  .mdc-switch__icons {
    display: none;
  }

  .mdc-elevation-overlay {
    background-color: var(--shade9);
  }

  .mdc-switch__shadow {
    box-shadow: none;
    border: 1px solid var(--shade5);
  }
}

.mat-mdc-slide-toggle {
  .mdc-label {
    color: var(--shade1);
    padding-left: 10px;
  }
}

.slide-toggle-group {
  & > span {
    margin-right: 11px;
  }
}

// Matt Stepper
.mat-step {
  &-header {
    flex-direction: column;
    padding: 8px 0 0 !important;
  }

  &-label {
    --mat-stepper-header-label-text-color: var(--shade4) !important;
    width: 100%;
    white-space: initial !important;
    text-overflow: unset !important;
    min-width: 40px !important;
    font-size: 12px !important;
    max-width: 52px;
    margin-top: 10px;

    &.mat-step-label-active {
      color: var(--shade1) !important;
    }
  }

  &-text-label {
    text-align: center;
  }

  &-icon {
    height: 12px !important;
    width: 12px !important;
    background-color: var(--shade9) !important;
    border: 1px solid var(--shade5) !important;
    margin: 3px !important;

    &-selected {
      background-color: $accent-green !important;
      border: none !important;
      width: 20px !important;
      height: 20px !important;
      margin: 0 !important;
      position: relative;

      .mat-step-icon-content {
        width: 18px;
        height: 18px;
        background-color: $accent-green !important;
        border: 2px solid var(--shade9) !important;
        border-radius: 50%;
      }
    }

    &-state-edit {
      background-color: $accent-green !important;
      border: none !important;
      width: 18px !important;
      height: 18px !important;
      margin: 0 !important;
      position: relative;
    }

    &-state-error {
      border: none !important;
      position: relative;
      width: 10px !important;
      height: 10px !important;
    }
  }

  &-icon-content {
    & > span {
      display: none;
    }
  }
}

.mat-horizontal-content-container {
  padding: 24px 24px 0 !important;
  margin: 0 -24px;
}

.mat-horizontal-stepper-header {
  &-container {
    position: relative;
    align-items: flex-start !important;
    padding: 0 0 8px;

    &:after {
      content: "";
      position: absolute;
      left: -24px;
      right: -24px;
      bottom: 0;
      height: 1px;
      background-color: var(--shade6);
    }
  }
}

.mat-stepper-horizontal {
  --mat-stepper-container-color: var(--shade9);
  margin: 10px 0 0;

  &-line {
    --mat-stepper-line-color: var(--shade6);
    border-top-width: 3px !important;
    margin: 16px -22px 0 !important;

    &:has(+ .mat-step-header[aria-selected="true"]),
    &:has(+ .mat-step-header[ng-reflect-state="edit"]),
    &:has(+ .mat-step-header[ng-reflect-state="error"]) {
      border-top-color: $accent-green !important;
    }
  }
}

// Mat Input
.mat-mdc-form-field {
  &-infix {
    min-height: 44px;
  }

  &.mat-focused {
    .mdc-text-field--focused {
    }
  }
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: transparent !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: var(--shade6) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: var(--shade6) !important;
}

.mdc-text-field {
  &--outlined {
    --mdc-outlined-text-field-focus-outline-width: 1px !important;
    --mdc-outlined-text-field-focus-outline-color: var(--shade6) !important;
    --mdc-outlined-text-field-focus-label-text-color: var(--shade5) !important;

    &:not(.mdc-text-field--disabled) {
      .mdc-text-field__input {
        --mdc-outlined-text-field-input-text-color: var(--shade2) !important;
        --mdc-outlined-text-field-caret-color: var(--shade2) !important;
      }
    }
  }

  &--filled {
    --mdc-filled-text-field-focus-active-indicator-height: 1px !important;
    --mdc-filled-text-field-active-indicator-color: transparent !important;
    --mdc-filled-text-field-focus-active-indicator-color: var(--shade2) !important;

    &:not(.mdc-text-field--disabled) {
      background-color: transparent !important;

      .mdc-text-field__input {
        --mdc-filled-text-field-caret-color: var(--shade2) !important;
      }
    }
  }

  &--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
    .mat-mdc-form-field-infix {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

// Mat Select
.mat-mdc-select-placeholder {
  color: var(--shade5) !important;
}

.mat-mdc-select-arrow {
  color: var(--shade5) !important;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  background-color: var(--shade9) !important;
}

.mat-mdc-option .mat-icon,
.mat-mdc-option .mat-pseudo-checkbox-full {
  color: var(--shade1) !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  color: var(--shade1) !important;
  width: 100%;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  border-color: var(--shade1) !important;
}

//Mat Slider
.mat-mdc-slider .mdc-slider__value-indicator {
  opacity: 1 !important;
  background-color: var(--shade1) !important;
}

.mat-mdc-slider .mdc-slider__value-indicator::before {
  border-top-color: var(--shade1) !important;
}

.mdc-slider__value-indicator-text {
  color: var(--shade9);
}

// Mat Radio Button
.mat-mdc-radio-button {
  --mdc-radio-unselected-icon-color: var(--shade6) !important;
}

// Mat Textarea
.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
  --mdc-outlined-text-field-outline-color: var(--shade6) !important;
}

// Matt Stepper
.mat-step {
  &-header {
    flex-direction: column;
    padding: 8px 0 0 !important;
  }

  &-label {
    --mat-stepper-header-label-text-color: var(--shade4) !important;
    width: 100%;
    white-space: initial !important;
    text-overflow: unset !important;
    min-width: 40px !important;
    font-size: 12px !important;
    max-width: 61px;
    margin-top: 10px;

    &.mat-step-label-active {
      color: var(--shade1) !important;
    }
  }

  &-text-label {
    text-align: center;
  }

  &-icon {
    height: 12px !important;
    width: 12px !important;
    background-color: var(--shade9) !important;
    border: 1px solid var(--shade5) !important;
    margin: 3px !important;

    &-selected {
      background-color: $accent-green !important;
      border: none !important;
      width: 20px !important;
      height: 20px !important;
      margin: 0 !important;
      position: relative;

      .mat-step-icon-content {
        width: 18px;
        height: 18px;
        background-color: $accent-green !important;
        border: 2px solid var(--shade9) !important;
        border-radius: 50%;
      }
    }

    &-state-edit {
      background-color: $accent-green !important;
      border: none !important;
      width: 18px !important;
      height: 18px !important;
      margin: 0 !important;
      position: relative;
    }

    &-state-error {
      border: none !important;
      position: relative;
      width: 10px !important;
      height: 10px !important;
    }
  }

  &-icon-content {
    & > span {
      display: none;
    }
  }
}

.mat-horizontal-content-container {
  padding: 24px 24px 0 !important;
  margin: 0 -24px;
}

.mat-horizontal-stepper-header {
  &-container {
    position: relative;
    align-items: flex-start !important;
    padding: 0 0 8px;

    &:after {
      content: "";
      position: absolute;
      left: -24px;
      right: -24px;
      bottom: 0;
      height: 1px;
      background-color: var(--shade6);
    }
  }
}

.mat-stepper-horizontal {
  --mat-stepper-container-color: var(--shade9);
  margin: 10px 0 0;

  &-line {
    --mat-stepper-line-color: var(--shade6);
    border-top-width: 3px !important;
    margin: 16px -22px 0 !important;

    &:has(+ .mat-step-header[aria-selected="true"]),
    &:has(+ .mat-step-header[ng-reflect-state="edit"]),
    &:has(+ .mat-step-header[ng-reflect-state="error"]) {
      border-top-color: $accent-green !important;
    }
  }
}

// Mat Input
.mat-mdc-form-field {
  &-infix {
    min-height: 44px;
  }
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--shade1);
}

.mdc-text-field {
  &--outlined {
    --mdc-outlined-text-field-focus-outline-width: 1px !important;
    --mdc-outlined-text-field-focus-outline-color: var(--shade6) !important;
    --mdc-outlined-text-field-focus-label-text-color: var(--shade5) !important;
  }

  &--filled {
    --mdc-filled-text-field-focus-active-indicator-height: 0px !important;
    --mdc-filled-text-field-active-indicator-color: transparent !important;
    --mdc-filled-text-field-disabled-container-color: var(--shade9) !important;

    &:not(.mdc-text-field--disabled) {
      background-color: transparent !important;
    }
  }

  &--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
    .mat-mdc-form-field-infix {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

// Mat Radio Button
.mat-mdc-radio-button {
  --mdc-radio-unselected-icon-color: var(--shade6) !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--shade1) !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: var(--shade1) !important;
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.7) !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  color: var(--shade5) !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-floating-label,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-floating-label--float-above {
  color: var(--shade5) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-floating-label--float-above {
  color: var(--shade5) !important;
}

/* Slider */
::ng-deep .mat-mdc-slider .mdc-slider__thumb-knob {
  background-color: white !important;
  border-color: black !important;
}

::ng-deep .mat-mdc-slider .mdc-slider__thumb-knob {
  border-width: 1px !important;
}

::ng-deep .mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: inherit !important;
}

::ng-deep .mat-mdc-slider .mdc-slider__track--inactive {
  background-color: gray !important;
}

/* Autocomplete */
::ng-deep .dark .cdk-overlay-pane {
  border: none !important;
}

::ng-deep {
  .mat-mdc-autocomplete-panel,
  .mat-mdc-select-panel {
    border: 1px solid var(--shade7) !important;
    background-color: var(--shade9) !important;
  }
}

/* Chips */

::ng-deep {
  .mat-mdc-standard-chip {
    --mdc-chip-container-shape-radius: 0px !important;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: var(--shade8) !important;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
    .mdc-evolution-chip__text-label {
    color: var(--shade1) !important;
  }
}

// Buttons
::ng-deep .mat-green-light {
  color: $accent-green-light !important;
  border-color: $accent-green-light !important;
}

// Remove arrows on numbers input
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//mat-button-toggle-group
.mat-button-toggle-standalone:not([class*="mat-elevation-z"]),
.mat-button-toggle-group:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  border: 1px solid var(--shade4);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  --mat-legacy-button-toggle-height: 24px;
}

.mat-button-toggle-checked {
  background-color: var(--shade1) !important;
  color: var(--shade9) !important;
}

.mat-button-toggle {
  color: var(--shade1);
}

.ngx-mat-timepicker {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
