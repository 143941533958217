@import "variables";
@import "material";

:root {
  /* For old Safari */
  padding-top: constant(safe-area-inset-top);
  /* For modern Safari */
  padding-top: env(safe-area-inset-top);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: var(--shade9);
  -webkit-appearance: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: var(--shade5);
  box-shadow: 0 0 1px rgba(var(--shade9), 0.5);
}

html,
body {
  overflow: hidden;
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  scroll-behavior: smooth;

  &.cdk-global-scrollblock {
    overflow: hidden !important;
  }
}

body {
  position: relative;
  box-sizing: border-box;
  font: 400 14px/1 Roboto, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--shade4);
  background-color: var(--shade9);

  &.dark {
    @include darken();
  }

  &.light {
    @include lighten();
  }
}

h1,
h2,
h3 {
  font: 700 30px/1.1 Roboto, Arial, Helvetica, sans-serif;
  color: var(--shade1);
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

label {
  font-size: 12px;
  color: var(--shade5);
}

.fas,
.far {
  font-size: 16px;
}

.animate-slide-in-left {
  animation-name: slideInLeft;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.user-avatar.loaded {
  opacity: 1 !important;
  border: 1px solid var(--shade6);
}

// Notes
.note {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 21px auto;
  grid-template-areas: "avatar title" "avatar message";
  gap: 9px;
  width: 100%;
  margin: 8px 0 0;

  &-avatar {
    grid-area: avatar;
  }

  &-title {
    display: flex;
    grid-area: title;
    align-items: flex-end;
    column-gap: 10px;
    font-size: 12px;
    line-height: 1.2;
    color: var(--shade5);
  }

  &-message {
    grid-area: message;
    padding: 11px 19px;
    font-size: 14px;
    background-color: var(--shade8);

    &.editor {
      padding: 0;
      background-color: transparent;

      .message {
        padding: 15px;
        border-radius: 5px;
        background-color: var(--shade8);
      }

      button {
        margin: 10px 0;
        color: var(--shade9);

        &.custom-button {
          color: var(--shade5);
        }

        &:hover {
          color: var(--shade7);
        }
      }
    }
  }
}

// Quill Editor
quill-editor {
  display: block;
  width: 100%;
}

.ql-snow {
  &.ql-toolbar {
    border-color: var(--shade7);
    border-radius: 6px 6px 0 0;
    background-color: inherit;
  }

  &.ql-container {
    min-height: 80px;
    border-color: var(--shade7);
    border-radius: 0 0 6px 6px;
    background-color: inherit;
  }

  .ql-tooltip.ql-editing {
    left: 25px !important;
  }

  .ql-stroke {
    stroke: var(--shade5);
  }

  .ql-formats {
    button {
      &:hover {
        color: var(--shade3);

        .ql-stroke {
          stroke: var(--shade3);
        }
      }
    }
  }
}

.mobile {
  @media screen and (min-width: $desktop) {
    display: none;
  }
}

// Tooltip
.tooltip-hover {
  position: relative;
  overflow: visible;

  .tooltip-text {
    position: absolute;
    z-index: 998;
    display: flex;
    visibility: hidden;
    align-items: center;
    padding: 6px 10px;
    transition: opacity 0.6s;
    opacity: 0;
    color: var(--shade1);
    border-radius: 4px;
    background-color: var(--shade9);
    white-space: nowrap;
    border: 1px solid var(--shade6);

    &.tooltip-top,
    &.tooltip-right,
    &.tooltip-bottom,
    &.tooltip-left {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.14),
        0 8px 10px 0 rgba(0, 0, 0, 0.12);
    }

    &.tooltip-top {
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
    }

    &.tooltip-right {
      top: 50%;
      right: -20px;
      transform: translate(100%, -50%);
    }

    &.tooltip-bottom {
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, 100%);
    }

    &.tooltip-left {
      top: 50%;
      left: -20px;
      transform: translate(-100%, -50%);
    }

    &.visible,
    &.access {
      visibility: visible;
      opacity: 1;
    }
  }
}

.tooltip-hover {
  &:hover {
    .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }
}

// .mat-mdc-text-field-wrapper {
//   height: 51px !important;
// }

//snack-bar
.mat-snack-bar-container {
  background-color: var(--shade1);
  color: var(--shade9);
}
.mat-simple-snackbar-action {
  color: $shade9;
}

.success-snackbar .mdc-snackbar__surface {
  background-color: $accent-green !important;
  color: $shade9 !important;
}
.error-snackbar .mdc-snackbar__surface {
  background-color: $accent-red !important;
  color: $shade9 !important;
}

.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: transparent;
}

.cdk-overlay-pane:has(.mat-datepicker-content.right) {
  transform: translateX(133px) !important;
}

.cdk-overlay-pane:has(.mat-datepicker-content.left) {
  transform: translateX(-30px) !important;
}

div.cdk-overlay-backdrop[class*=" mat-datepicker-"] {
  z-index: 999 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: var(--shade1) !important;
  background-color: var(--shade1) !important;
}

.mdc-checkbox:hover .mdc-checkbox__native-control:checked ~ .mdc-checkbox__ripple {
  background-color: var(--shade1) !important;
}

@media screen and (max-width: $desktop) {
  html,
  body {
    overflow: visible;
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0;
    overscroll-behavior: none;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &.cdk-global-scrollblock {
      overflow: hidden !important;
    }
  }
}

/* By default for mobile */
@media screen and (max-width: 850px) {
  .dialog-driver {
    .mat-mdc-dialog-content {
      max-height: 100vh;
    }
  }
}

/* For Safari mobile */
@supports (-webkit-touch-callout: none) {
  @media screen and (max-width: 850px) {
    .dialog-driver {
      .mat-mdc-dialog-content {
        max-height: 90vh;
      }
    }
  }
}
