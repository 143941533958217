@import "colors";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local("Roboto-Light"),
    url("../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("Roboto-Regular"),
    url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local("Roboto-Bold"),
    url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

// sizes
$size-headerHeight: 70px;

// Breakpoints
$desktop: 960px;
$mobile: 768px;
